<template>
  <div class="w-full h-screen bg-fade">
    <div class="pt-20 mb-5">
      <img class="object-contain h-16 w-full" src="../assets/mle-logo-light.png" />
    </div>
    <div class="text-center">
      <div class="rounded-lg bg-gray-100 inline-block py-6 px-8 text-left ">
        <p class="bg-red-600 text-white py-2 px-3 mb-5" v-if="error" id="error">{{error}}</p>
        <div class="py-10" v-if="!loadingId && !exists">
          New account token could not be found. Please contact us if you think this was a mistake.
        </div>
        <form @submit.prevent="submit" v-if="!loadingId && exists">
          <div class="text-center text-2xl mb-5">Setup Account</div>
          <div class="flex">
            <div class="mb-5 flex-grow">
              <input class="px-3 py-2 w-full shadow focus:outline-none focus:ring focus:ring-green-400" type="text" v-model="form.first_name" placeholder="First Name" />
            </div>
            <div class="mb-5 ml-5 flex-grow">
              <input class="px-3 py-2 w-full shadow focus:outline-none focus:ring focus:ring-green-400" type="text" v-model="form.last_name" placeholder="Last Name" />
            </div>
          </div>
          <div class="flex">
            <div class="mb-5 flex-grow">
              <input class="px-3 py-2 w-full shadow focus:outline-none focus:ring focus:ring-green-400" type="password" v-model="form.password" placeholder="Password" />
            </div>
            <div class="mb-5 ml-5 flex-grow">
              <input class="px-3 py-2 w-full shadow focus:outline-none focus:ring focus:ring-green-400" type="password" v-model="form.confPassword" placeholder="Confirm Password" />
            </div>
          </div>
          <hr class="mb-5">
          <div class="mb-5">
            <input class="px-3 py-2 w-full shadow focus:outline-none focus:ring focus:ring-green-400" type="text" v-model="form.address" placeholder="Address" />
          </div>
          <div class="mb-5">
            <input class="px-3 py-2 w-full shadow focus:outline-none focus:ring focus:ring-green-400" type="text" v-model="form.address_two" placeholder="Address 2" />
          </div>
          <div class="flex">
            <div class="mb-5">
              <input class="px-3 py-2 shadow focus:outline-none focus:ring focus:ring-green-400" type="text" v-model="form.city" placeholder="City" />
            </div>
            <div class="mb-5 ml-5">
              <input class="px-3 py-2 shadow w-32 focus:outline-none focus:ring focus:ring-green-400" type="text" v-model="form.state" placeholder="State" />
            </div>
            <div class="mb-5 ml-5">
              <input class="px-3 py-2 shadow w-20 focus:outline-none focus:ring focus:ring-green-400" type="text" v-model="form.zip" placeholder="Zip" />
            </div>
          </div>
          <hr class="mb-5">
          <div class="mb-5">
            <input class="px-3 py-2 shadow focus:outline-none focus:ring focus:ring-green-400" type="text" v-model="form.phone_number" placeholder="Phone" />
          </div>
          <div>
            <button class="bg-green w-full rounded-md text-white p-1 shadow" type="submit">Submit</button>
          </div>
        </form>
      </div>
      <h2 class="text-white text-xl text-green my-5 font-bold">REPRESENTATIVE CENTER</h2>
    </div>
  </div>
</template>
<style lang="scss">
  .bg-fade {
    background: rgb(51,51,51);
    background: linear-gradient(90deg, rgba(51,51,51,1) 0%, rgba(34,34,34,1) 100%);
  }
</style>
<script>
import { mapActions } from "vuex";
export default {
  data: () => {
    return {
      id: null,
      error: '',
      loadingId: true,
      exists: true,
      successful: false,
      form: {
        first_name: '',
        last_name: '',
        password: '',
        confPassword: '',
        address: '',
        address_two: '',
        city: '',
        state: '',
        zip: '',
        phone_number: '',
      },
    }
  },

  computed: {
    tempUser: function () { return this.$store.state.auth.tempUser },
  },

  created: function () {
    this.loadUserId();
  },

  methods: {
    ...mapActions(['Register']),
    async loadUserId() {
      this.loadingId = true;
      await this.$store.dispatch('GetUserIdByToken', this.$route.params.token);
      this.exists = this.tempUser.id > 0;
      document.title = 'MLE Rep Center | Register';
      this.loadingId = false;
    },

    async submit() {
      this.validate(this.form);

      try {
        this.form.token = this.$route.params.token;
        await this.Register(this.form);

        this.$router.push('/');
      } catch (error) {
        this.successful = false;
        this.error = 'Failed';
      }
    },

    validate(form) {
      this.error = '';
      if (form.password !== form.confPassword) {
        return this.error = 'Passwords do not match';
      }
      if (!form.first_name) {
        return this.error = 'Cannot leave first name field blank';
      }
      if (!form.password) {
        return this.error = 'Cannot leave password field blank';
      }
      if (!form.last_name) {
        return this.error = 'Cannot leave last name field blank';
      }
      if (!form.address) {
        return this.error = 'Cannot leave address field blank';
      }
      if (!form.city) {
        return this.error = 'Cannot leave city field blank';
      }
      if (!form.state) {
        return this.error = 'Cannot leave state field blank';
      }
      if (!form.zip) {
        return this.error = 'Cannot leave zip field blank';
      }
      if (!form.phone_number) {
        return this.error = 'Cannot leave phone field blank';
      }
    }
  }
}
</script>
